import DelimitersCentralWrapper from "@jordibosch20/software_tools_package/dist/pages/DelimitersCentralWrapper.component.js"
import styles from "./App.module.css";
import { getDelimiters } from "@jordibosch20/software_tools_package/dist/utils/delimiters.js";
import { useAnalytics } from './useAnalytics';

function App() {
  useAnalytics();
  return (
    <div className={styles.AppWrapper}>
      <div className={styles.App}>
        <DelimitersCentralWrapper title={"Delimiters"} f={getDelimiters}></DelimitersCentralWrapper>
      </div>
      <div className={styles.text}>
        <h3>Introduction</h3>
        <p>&nbsp;Whether you're a software developer, a data scientist, or just someone who loves keeping lists neat and tidy, you know the importance of having well-organized and correctly separated by delimiters information.</p>
        <p>That's where the Delimiter Addition Tool comes in handy.&nbsp;Offering a simple and powerful solution for adding delimiters to your text files, data and raw lines, ensuring they're perfectly prepped for processing, analysis, or whatever else you need. Let's dive into how this tool can make your life easier and transform your data formatting tasks from tedious to trivial.</p>
        <h3>Why Delimiters Matter</h3>
        <p>Delimiters separate individual pieces of data, making it easier for software programs to understand and manipulate this information. Common delimiters include commas, semicolons, and tabs, and many more, each depending on the user usecase.</p>
        <p>Without proper delimitation, data can become a mess, difficult to sort, analyze, or even read. This is where our Delimiter Addition Tool shines, providing a straightforward solution to a potentially complex problem.</p>
        <h3>How the Delimiter Addition Tool Works</h3>
        <p>Our Delimiter Addition Tool is designed with simplicity and efficiency in mind. Here's a step-by-step guide on how it works:</p>
        <ol>
        <li><strong>Input Your Data:</strong> Start by pasting your text or data into the left hand side textare. Whether it's a list of email addresses, a series of numbers, or just raw lines, paste them in the left hand side input.</li>
        <li><strong>Choose Your Delimiter:</strong> Select the delimiter you'd like to append to the end of each line or at the beginning of each lines. You can input what is the delimiter you would like and in which position of the sentence (beginning or end)</li>
        <li><strong>Process:</strong> With the click of the "convert button", our tool adds the delimiters to your input lines and pastes them in the right hand side text input. You will see a very hand copy to clipboard button.</li>
        </ol>
        <h3>Real-World Applications</h3>
        <p>The beauty of the Delimiter Addition Tool lies in its multiple use cases it solves. Here are just a few scenarios where it can be a game-changer:</p>
        <ul>
        <li><strong>Software Development:</strong> When dealing with code, especially in languages like SQL or when formatting arrays, having your data delimited correctly can save time and reduce errors.</li>
        <li><strong>Data Analysis:</strong> Analysts often work with large datasets that require uniform formatting. This is were it comes in handy to add for example a comma after each line (this way you can then create an array directly in the code).</li>
        <li><strong>List Management:</strong> From managing mailing lists to organizing inventory, appending delimiters can help streamline the creation of CSV files or other list formats that require precise organization.</li>
        </ul>
        <div className={styles.links}>
          <h3>Other similar resoures that could be helpful</h3>

          <li><a href="https://check-json.com">JSON format checker</a></li>
          <li><a href="https://check-xml.com">XML Checker</a></li>
          <li><a href="https://check-yaml.com">YAML Checker</a></li>
          <li><a href="https://formatter-json.com">JSON formatter</a></li>
          <li><a href="https://add-delimiters.com">Add delimiters to sentences</a></li>
          <li><a href="https://convert-base.com">Convert base</a></li>
          <li><a href="https://convert-binary.com">Convert text/file to binary</a></li>
          <li><a href="https://convert-hexadecimal.com">Convert text/file to hexadecimal</a></li>
          <li><a href="https://convert-base64.com">Convert text/file to base64</a></li>
          <li><a href="https://css-beautify.com">CSS beautifier</a></li>
          <li><a href="https://html-beautify.com">HTML beautifier</a></li>
          <li><a href="https://javascript-beautify.com">Javascript beautifier</a></li>
          <li><a href="https://xml-beautify.com">XML beautifier</a></li>
          <li><a href="https://extract-email.com">Email extractor</a></li>
          <li><a href="https://extract-phone.com">Phone extractor</a></li>
          <li><a href="https://extract-url.com">URL extractor</a></li>
          <li><a href="https://json-2-csv.com">JSON to CSV online converter</a></li>
          <li><a href="https://json-to-yaml.com">JSON to YAML online converter</a></li>
          <li><a href="https://software-kit.com">Software tools</a></li>
          <li><a href="https://svg2css.com">SVG to CSS background </a></li>
          <li><a href="https://trim-text.com">Text trimmer</a></li>
          <li><a href="https://url-coder.com">URL encoder/decoder</a></li>
          <li><a href="https://yaml2json.com">YAML to JSON</a></li>
          <li><a href="https://csv-to-json.com">CSV to JSON</a></li>
          <li><a href="https://sort-text.com">Sort text</a></li>
          <li><a href="https://convert-casing.com">Convert casing</a></li>
          <br></br>
          <br></br>
          <br></br>
          <span>In case you have any <b>suggerence or found any bug</b> in our tools, please, feel free to email us at statisticskingdom @ gmail.com (without the spaces) and we will take care of it.</span>
        </div>
      </div>
    </div>
  );
}

export default App;